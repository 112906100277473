import React from 'react'

const UITextArea = ({label, className ='', placeholder = 'ingresar', onChange, name, ...rest}) => {
  return (
    <div className='w-full md:my-5 my-2'>
        <label htmlFor={name} className='m-1 font-bold'>{label}</label>
        <textarea id={name} name={name} rows="4" placeholder={placeholder} onChange={onChange}
          className={`p-3 rounded-lg bg-white w-full resize-none ${className}`} {...rest}>
        </textarea>
    </div>
  )
}

export default UITextArea