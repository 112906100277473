import React from 'react'

const UIButton = ({className, text, type='', onclick}) => {

  return (
    <button type={type} className={`${className} bg-custom-blue p-2 rounded-lg text-white`} onClick={onclick}>
        {text}
    </button>
  )
}

export default UIButton