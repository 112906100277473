import React from 'react'
import Title from '../UI/Title'
import ItemCompanie from './item'

const Companies = () => {
  const companies = [
    {
      title:'Honey Mustard',
      img:'./images/logo-hm.png',
      text:'En Honey Mustard, nuestro enfoque principal es impulsar la transformación digital de tu empresa a través de soluciones de software totalmente personalizadas, con un fuerte enfoque en la experiencia del usuario.',
      style:'',
      link:'https://www.honeymustard.mx/'
    },
    {
      title:'Condesa Films',
      img:'./images/logo-condesa.png',
      text:'Especialistas en estrategia, conceptos publicitarios y comunicación empresarial. nuestro servicio, 100% personalizado, te lleva de la mano para crear contenidos efectivos que impacten a la audiencia que estás buscando.',
      style:'bg-[#343434] rounded-xl ',
      link:'https://www.honeymustard.mx/'
    },
    {
      title:'Toxic Laboratory',
      img:'./images/logo-toxic.png',
      text:'Nuestro enfoque se centra en brindar soluciones innovadoras y de alta calidad que satisfagan las necesidades de visualización arquitectónica y renderización, con el objetivo de generar un impacto significativo.',
      style:'',
      link:'https://www.honeymustard.mx/'
    },
    {
      title:'Mappen',
      img:'./images/logo-mappen.png',
      text:'En Mappen, nos especializamos en ofrecer soluciones innovadoras para el sector inmobiliario que te ayudarán a llevar tu proceso de venta y posventa al siguiente nivel.',
      style:'',
      link:'https://mappen.mx/'
    },
  ]

  return (
    <div className='py-12 mx-[10%]' id='companies'>
       <Title seccion={'Nuestras Empresas'} titulo={'Conoce de dónde vienen las mentes maestras'} 
       subtitle={'4 empresas que juntas complementan cada aspecto de tus necesidades.'}/>
       <div className='grid grid-cols-1 gap-2 justify-center items-center justify-items-center md:grid-cols-2 max-md:mt-8'>
          {companies.map((companie,key) =>
            <ItemCompanie key={key} companie={companie} />
          )}
       </div>
    </div>
  )
}

export default Companies