import React from 'react'

const Title = ({seccion, titulo, subtitle}) => {
  return (
    <div className='text-center mt-4'>
        <p className=' text-custom-blue uppercase font-semibold'>{seccion}</p>
        <h2 className='text-3xl md:text-4xl font-bold my-3 md:mx-[10%] md:w-[80%] mx-[5%] lg:mx-[25%] w-[90%] lg:w-[50%]'>{titulo}</h2>
        <p className=''>{subtitle}</p>
    </div>
  )
}

export default Title