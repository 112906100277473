import React from 'react'

const ItemCompanie = ({companie}) => {
  return (
    <div className='flex flex-col items-center w-[85%] md:w-[75%] text-center md:my-12 my-4'>
        <div className={`${companie.style} flex justify-center my-5 p-1`} >
            <img src={companie.img} className='px-1 py-0 h-[80px]'/>
        </div>
        <h3 className='font-semibold mb-2'>{companie.title}</h3>
        <p>{companie.text}</p>
        <a className='text-custom-blue mt-1' target='_blank' href={companie.link}> Conoce mas <span className=' text-[14px]'>▶</span></a>
    </div>
  )
}

export default ItemCompanie