import React from 'react'

const UIInput = ({label, type = 'text', className='', onChange, name, value, ...rest}) => {
  return (
    <div className='w-full md:my-5 my-2'>
        <label htmlFor={name} className='m-1 font-bold'>{label}</label>
        <input id={name} name={name} type={type} value={value} placeholder='ingresar' onChange={onChange}
            className={` p-3 rounded-lg bg-white w-full  ${className}`} {...rest} />
    </div>
  )
}

export default UIInput