import React from 'react'
import UIButton from '../UI/UIButton'

const Banner = () => {

  return (
    <div id='home' className='flex md:flex-row flex-col my-[2rem] max-lg:mt-[5rem] mx-[10%] items-center justify-between'>
        <div className='w-[100%] md:w-[45%]'>
          <h1 className='text-4xl md:text-[4vw] my-2 font-semibold lg:leading-[4vw]'>
            Ética, 
            <span className='text-custom-blue'> Creatividad </span> 
            y Tecnología en Acción
          </h1>
          <p className='my-5 text-lg'>
            ¿Tienes un desarrollo inmobiliario? Nosotros te ayudamos a impulsarlo, 
            cubriendo tus necesidades de venta y exposición.
            </p>
         <a href='#companies'>
          <UIButton className={`px-7`} text={'Explora Nuestras Empresas'} />
         </a>
        </div>

        <div className='w-[100%] md:w-[50%] py-10 md:p-0 lg:p-12'>
          <img src='./images/bannerImg.png' className='w-[100%]'/>
        </div>
    </div>
  )
}

export default Banner