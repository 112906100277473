import React from 'react'

const About = () => {
  return (
    <div id='about' className='flex md:flex-row flex-col py-[3rem] bg-custom-gray justify-center items-center '>
        <div className='w-[80%] md:w-[45%] py-10 md:p-0 lg:p-12'>
          <img src='./images/image-female.png' className='w-[80%] mx-[10%] rounded-md'/>
        </div>
        <div className='w-[80%] md:w-[45%]'>
            <p className=' text-custom-blue uppercase font-semibold'>ACERCA DE NOSOTROS</p>
            <h2 className='text-4xl md:text-[3.6vw] my-3 font-semibold lg:leading-[4vw]'>
                Finalmente, el conjunto de 
                <span className='text-custom-blue'> soluciones </span> 
                que necesitabas
            </h2>
            <p className='mt-5 w-[100%] md:w-[100%] text-lg'>
            La creatividad es el motor que impulsa nuestras soluciones. Nuestro equipo multidisciplinario y 
            apasionado está constantemente buscando nuevas formas de abordar los desafíos del mercado inmobiliario. 
            Esto se traduce en estrategias innovadoras, diseños atractivos y soluciones personalizadas que le darán 
            una ventaja competitiva.
            </p>
        </div>
    </div>
  )
}

export default About