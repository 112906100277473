import React from 'react'

const Footer = () => {
  return (
    <div className='py-8'>
      <p className='text-center'>Harmony ®2023</p>
    </div>
  )
}

export default Footer