import React, {useState, useEffect} from 'react'

const Navbar = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const [seccionActive, setSeccionActive] = useState('home')

    const toggleMenu = (e) => {
        setSeccionActive(e.target.name)
        setOpenMenu(!openMenu);
      };

      useEffect(() => {
        function handleScroll() {
          const divs = document.querySelectorAll('div');
    
          divs.forEach((div) => {
            const rect = div.getBoundingClientRect();
            if(!div.id || !rect) return

            if (rect.top <= 350) {
                setSeccionActive(div.id);
            }
          });
        }
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  return (
   <div className='fixed w-full bg-white top-0'>
     <div className='flex justify-between px-[5%] md:px-[3%] lg:px-[5%] shadow-md'>
        <div className=''>
            <img src='./images/iconHV.png'  className='py-4 lg:p-4'/>
        </div>

        <nav className='md:flex max-md:hidden justify-between max-md:bg-white'>
            <ul className='flex'>
                <li className='p-4 max-lg:px-1.5'>
                    <a href='#home' name='home' className={`${seccionActive == 'home' && 'text-custom-blue'}`} 
                    onClick={toggleMenu}>Inicio</a>
                </li>
                <li className='p-4 max-lg:px-1.5'>
                    <a href='#about' name='about' className={`${seccionActive == 'about' && 'text-custom-blue'}`} 
                    onClick={toggleMenu}>Acerca de Nosotros</a>
                </li>
                <li className='p-4 max-lg:px-1.5'>
                    <a href='#companies' name='companies' className={`${seccionActive == 'companies' && 'text-custom-blue'}`} 
                    onClick={toggleMenu}>Nuestras Empresas</a>
                </li>
                <li className='p-4 max-lg:px-1.5'>
                    <a href='#contact' name='contact' className={`${seccionActive == 'contact' && 'text-custom-blue'}`} 
                    onClick={toggleMenu}>Contacto</a>
                </li>
            </ul>           
        </nav>

    <div className='md:hidden'>
        <div className=" text-custom-blue py-3 md:hidden">
            <button className="text-3xl" onClick={()=>setOpenMenu(!openMenu)}>
                &#9776; 
            </button>
        </div>
        {openMenu && (
            <div className="md:hidden shadow-lg bg-white p-4 absolute z-30 top-[60px] right-12">
                <ul className=''>
                    <li className='py-2'>
                        <a href='#home' name='home' className={`${seccionActive == 'home' && 'text-custom-blue'}`}
                         onClick={toggleMenu}>Inicio</a>
                    </li>
                    <li className='py-2'>
                        <a href='#about' name='about' className={`${seccionActive == 'about' && 'text-custom-blue'}`} 
                        onClick={toggleMenu}>Acerca de Nosotros</a>
                    </li>
                    <li className='py-2'>
                        <a href='#companies' name='companies' className={`${seccionActive == 'companies' && 'text-custom-blue'}`}
                        onClick={toggleMenu}>Nuestras Empresas</a>
                    </li>
                    <li className='py-2'>
                        <a href='#contact' name='contact' className={`${seccionActive == 'contact' && 'text-custom-blue'}`}
                         onClick={toggleMenu}>Contacto</a>
                    </li>
                </ul>           
            </div>
        )}
        </div>
    </div>
   </div>
  )
}

export default Navbar