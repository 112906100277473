import React, { useState } from 'react'
import Title from '../UI/Title'
import UIInput from '../UI/UIInput'
import UIButton from '../UI/UIButton'
import UITextArea from '../UI/UITextArea'

const Contact = () => {
  const [formData, setFormData] = useState({
    nombre:'',
    telefono:'',
    email:'',
    mensaje:''
  })
  const {nombre, telefono, email, mensaje} = formData
  const onChange = (e) =>{
    setFormData(prev => ({...prev, [e.target.name]:e.target.value}))
  }

  const handleSummit = (e) =>{
    e.preventDefault();

    var data = {
      service_id: 'service_gtcovms',
      template_id: 'template_q6k4kn7',
      user_id: 'CfJMlMICr55TyIVbz',
      template_params: formData
  };
  fetch('https://api.emailjs.com/api/v1.0/email/send', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {"Content-type": "application/json; charset=UTF-8"}
    }).then(() => {
      setFormData({
        nombre:'',
        telefono:'',
        email:'',
        mensaje:''
      })
      alert('Your mail is sent!');
    }).catch((error) =>{
        alert('Oops... Correo no enviado');
    });

  } 
  
  const handleNumber = (e) => {
    const valor = e.target.value;

    const numeros = valor.replace(/[^0-9]/g, '');

    setFormData(prev => ({...prev, [e.target.name]:numeros}))
  };

  return (
    <div id='contact' className='bg-custom-gray p-[3rem]'>
      <Title seccion={'Contacto'} titulo={'Envíanos un mensaje'} subtitle={'Resolveremos todas tus dudas'}/>
      <form className='md:mx-[15%] mx-[5%] my-10' onSubmit={handleSummit}>
        <UIInput required label={'Nombre'} name='nombre' onChange={onChange} value={nombre}/>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-3'>
          <UIInput  maxLength={10} minLength={10} required label={'Teléfono'} name='telefono' type='text' onChange={handleNumber} value={telefono}/>
          <UIInput required label={'Correo electrónico'} name='email' type='email' onChange={onChange} value={email}/>
        </div>
        <UITextArea required label={'Mensaje'} name='mensaje' onChange={onChange} value={mensaje}/>
        <div className='relative h-10'>
          <UIButton required type='summit' text={'ENVIAR'} className='px-12 absolute inset-y-0 right-0'/>
        </div>
      </form>
    </div>
  )
}

export default Contact